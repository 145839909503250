/* General Reset */
html, body {
  margin: 0;
  padding: 0;
}
body {
  background: #f0f0f0;
  font-family: 'Roboto', sans-serif;
}
p {
  line-height: 1.35;
}

/* Container Layout */
.container {
  display: grid;
  grid-gap: 10px;
  grid-template-areas: 
    "aside"
    "main";
  width: 100%;
  margin: 0 auto;
}

@media (min-width: 500px) {
  .container {
    grid-template-columns: 30% 1fr;
    grid-template-areas: 
      "aside main";
    width: 992px;
  }
}
@media (min-width: 992px) {
  .container {
    grid-template-columns: 30% 1fr;
    grid-template-areas: 
      "aside main";
    width: 1170px;
  }
}

main {
  grid-area: main;
}
aside {
  grid-area: aside;
}
aside .inner, main .inner {
  background: #fff;
  padding: 20px;
}

/* List Reset */
ul {
  padding: 0;
  list-style: none;
}

/* Typography */
h1 {
  font-size: 24px;
  margin-bottom: 0;
  font-weight: 700;
}
h2 {
  font-size: 20px;
  margin-top: 10px;
  color: #777;
}
h3 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0;
}
h4 {
  font-size: 15px;
  font-weight: 700;
}

/* Section Titles */
section {
  margin-bottom: 4rem;
}
section h2 {
  padding-bottom: 10px;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 20px;
}

/* Links */
a {
  -webkit-transition: all 0.5s ease-out;
  -moz-transition: all 0.5s ease-out;
  -o-transition: all 0.5s ease-out;
  -ms-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}

/* Dividers */
.divider {
  padding: 10px 20px 0 20px;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 20px;
}

/* Font Awesome Icons */
.fa {
  color: #303030;
  margin-right: .8rem;
  top: 4px;
  transition: color 0.3s ease;
}

/* Contact Links */
.contact-links li {
  font-size: .9rem;
  margin-bottom: 15px;
}

/* Profile Links */
.profileLinks li {
  display: inline-block;
}
.profileLinks li a {
  text-decoration: none;
  color: #000;
}
.profileLinks li a:hover {
  color: #9e9e9e;
}

/* Skills Section */
.skills-list {
  display: flex;
  flex-wrap: wrap;
}
.skills-list li {
  font-size: 18px;
  margin: 0 3px 5px;
}

/* Dates */
.workDates {
  color: #777;
}

/* Utility Classes */
.text-uppercase {
  text-transform: uppercase;
}
.text-center {
  text-align: center;
}
.center-block {
  display: block;
  margin: 0 auto;
}
.small-text {
  font-size: .8rem;
}
.img-circle {
  border-radius: 50%;
}

/* Labels */
.label {
  display: inline;
  padding: .2em .6em .3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
}
.label-success {
  background-color: #5cb85c;
}

/* Screenreader */
.sr-only {
  position: absolute !important;
  height: 1px; width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
}

/* PDF Button */
.pdf-button {
  position: fixed;
  top: 35px;
  right: 20px;
  padding: 10px 10px;
  background-color: #adadad;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  z-index: 1000;
}
.pdf-button:hover {
  background-color: #616161;
}

/* Dark Mode Styling */
body.dark-mode {
  background: #121212;
  color: #e0e0e0;
}

body.dark-mode .container aside .inner,
body.dark-mode .container main .inner {
  background: #1e1e1e;
}

body.dark-mode a {
  color: #80cbc4;
}

body.dark-mode .divider {
  border-bottom: 1px solid #333;
}

body.dark-mode h1, body.dark-mode h2, body.dark-mode h3, body.dark-mode h4 {
  color: #ffffff;
}

body.dark-mode .pdf-button {
  background-color: #bb86fc;
}

body.dark-mode .pdf-button:hover {
  background-color: #9a67ea;
}

.dark-mode-toggle {
  position: fixed;
  top: 35px;
  right: 40px; /* Positioned to the left of the PDF button */
  padding: 10px 10px;
  background-color: #4a4a4a;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  z-index: 1000;
}

.dark-mode-toggle:hover {
  background-color: #616161;
}
/* Default Icon Color */
.fa {
  color: #303030; /* Default dark gray for light mode */
  transition: color 0.3s ease; /* Smooth transition for color changes */
}

/* Dark Mode Icon Color */
body.dark-mode .fa {
  color: #539fcc; /* Light gray for dark mode */
}
/* Default Skill Box Styling */
.skill {
  background-color: #b46a2410; /* White background for light mode */
  color: #000000; /* Black text for light mode */
  border: 1px solid #e0e0e0; /* Light gray border */
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease; /* Smooth transitions */
}

/* Dark Mode Skill Box Styling */
body.dark-mode .skill {
  background-color: #35575f44; /* Dark gray background */
  border: 1px solid #858585; /* Darker border for separation */
}

/* Ensure Text Color Applies to Nested Elements */
body.dark-mode .skill,
body.dark-mode .skill * {
  color: #e0e0e0 !important; /* Light gray text for all elements inside */
}

/* Adjust the segmented bar for dark mode */
body.dark-mode .segment {
  background-color: #616161; /* Darker gray for unfilled segments */
}

body.dark-mode .segment.filled {
  background-color: #539fcc; /* Adjust filled segment color */
}

/* Responsive Skill Boxes */
@media (max-width: 768px) {
  .skills-grid {
    grid-template-columns: 1fr; /* Single-column layout for mobile */
  }
  .skill {
    font-size: 14px;
    padding: 8px;
  }
  .segmented-bar {
    gap: 2px;
  }
  .segment {
    height: 5px;
  }
  h1, h2, h3, h4 {
    font-size: 90%; /* Scale down headings */
  }
  body {
    font-size: 90%; /* Scale down text for smaller screens */
  }
}
