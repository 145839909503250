/* Skills Container */
.skills-container {
    display: flex;
    flex-direction: column;
    gap: 30px; /* Space between categories */
  }
  
  /* Skills Category Header */
  .skills-category {
    margin-bottom: 20px;
  }
  
  .category-name {
    font-size: 16px;
    font-weight: bold;
    color: #444;
    margin-bottom: 10px;
  }
  
  .category-level {
    font-size: 14px;
    color: #777;
  }
  
  /* Fixed 4-Column Grid */
  .skills-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Exactly 4 equal-width columns */
    gap: 20px; /* Space between boxes */
    justify-items: center; /* Center align items in the grid */
  }
  
  /* Fixed-Size Skill Boxes */
  .skill {
    width: 140px; /* Adjust width for 4-column layout */
    height: 30px; /* Reduced box height */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Even spacing inside the box */
    padding: 8px;
    background-color: #f9f9f9; /* Light background */
    border-radius: 5px; /* Rounded corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    text-align: center; /* Center-align text */
  }
  
  /* Skill Name */
  .skill-name {
    font-size: 13px; /* Compact font size */
    font-weight: bold;
    color: #333;
    margin-bottom: 6px; /* Reduce space between name and bar */
    white-space: nowrap; /* Prevent text wrapping */
    overflow: hidden;
    text-overflow: ellipsis; /* Add "..." if the name is too long */
  }
  
  /* Segmented Bar */
  .segmented-bar {
    display: flex;
    gap: 3px; /* Compact space between segments */
    justify-content: center;
  }
  
  .segment {
    flex-grow: 1; /* Ensure segments take equal space */
    height: 6px; /* Thin segment height */
    background-color: #e0e0e0; /* Base color */
    border-radius: 2px;
  }
  
  .segment.filled {
    background-color: #4caf50; /* Filled segment color */
  }
  /* Tooltip Container */
.segmented-bar {
    display: flex;
    gap: 3px; /* Compact space between segments */
    justify-content: center;
    position: relative; /* Required for positioning the tooltip */
  }
  
  .segmented-bar:hover .tooltip {
    visibility: visible; /* Show tooltip on hover */
    opacity: 1; /* Fade-in effect */
  }
  
  .tooltip {
    position: absolute;
    top: 30px; /* Position above the bar */
    left: 50%;
    transform: translateX(-50%);
    background-color: #333; /* Dark background for contrast */
    color: #fff; /* White text */
    padding: 5px 8px;
    border-radius: 5px;
    font-size: 12px; /* Small, unobtrusive font */
    white-space: nowrap;
    visibility: hidden; /* Hidden by default */
    opacity: 0; /* Fully transparent initially */
    transition: opacity 0.3s ease-in-out; /* Smooth fade-in effect */
    z-index: 10; /* Ensure it appears above other elements */
  }
  
  /* Individual Segment */
  .segment {
    flex-grow: 1; /* Ensure segments take equal space */
    height: 6px; /* Thin segment height */
    background-color: #e0e0e0; /* Base color */
    border-radius: 2px;
  }
  
  .segment.filled {
    background-color: #4caf50; /* Filled segment color */
  }
  